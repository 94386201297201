var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fill-width my-5"},[_c('v-expansion-panel',[_c('v-expansion-panel-header',{staticClass:"px-6 panel-header rounded-0 border pointer",scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-icon',{attrs:{"size":"24"}},[_vm._v(" mdi-plus")])]},proxy:true}])},[_c('h3',[_vm._v(_vm._s(_vm.$t("EMPLOYEES.REQUESTS")))])]),_c('v-expansion-panel-content',{staticClass:"border"},[_c('div',{},[_c('v-row',{staticClass:"pl-5 pr-5 pt-10 pb-10",attrs:{"justify":"space-between"}},[_c('v-text-field',{staticStyle:{"max-width":"400px","max-height":"45px","border-color":"white"},attrs:{"label":_vm.$t('EMPLOYEES.SEARCH'),"prepend-inner-icon":"mdi-magnify","single-line":"","hide-details":"","flat":"","outlined":"","solo":""},model:{value:(_vm.search_Temp),callback:function ($$v) {_vm.search_Temp=$$v},expression:"search_Temp"}}),_c('v-select',{staticStyle:{"max-width":"150px","max-height":"45px"},attrs:{"items":_vm.slots,"label":"Filter","outlined":"","flat":"","solo":""},on:{"change":function($event){return _vm.filterOnSelect()}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('h3',[_vm._v(_vm._s(_vm.$t("EMPLOYEES.STATUS." + item)))])]}},{key:"selection",fn:function(item){return [_c('span',[_vm._v(_vm._s(_vm.$t("EMPLOYEES.STATUS." + item.item)))])]}}]),model:{value:(_vm.enabled),callback:function ($$v) {_vm.enabled=$$v},expression:"enabled"}})],1),_c('v-data-table',{staticClass:"elevation-0",attrs:{"loading":_vm.loading,"headers":_vm.headers,"sort-by":['createdAt'],"sort-desc":"","items":_vm.app,"item-key":"name","search":_vm.search,"custom-filter":_vm.filterSearch},scopedSlots:_vm._u([{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_c('h3',[_vm._v(_vm._s(_vm.showDateAsMoment(item)))])]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [(item && item.user.type == 1)?_c('h3',[_vm._v(" "+_vm._s(((item.user.firstName) + " " + (item.user.lastName)))+" ")]):_vm._e(),(item && item.user.type == 2)?_c('h3',[_vm._v(_vm._s(item.user.company))]):_vm._e()]}},{key:"item.referenceNumber",fn:function(ref){
var item = ref.item;
return [_c('h3',[_vm._v(_vm._s(item.referenceNumber))])]}},{key:"item.existingCustomer",fn:function(ref){
var item = ref.item;
return [_c('v-badge',{attrs:{"color":item.user.sapIds ? 'success' : 'primary',"inline":"","content":item.user.sapIds ? _vm.$t('GENERAL.YES') : _vm.$t('GENERAL.NO')}}),_vm._v(" "+_vm._s(item.user.sapIds ? ("(" + (item.user.sapIds.internalUserId) + ")") : "")+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"pt-1 pb-1 pl-2 pr-2 rounded",staticStyle:{"color":"white"},style:({ background: _vm.statusColor(item.status) })},[_vm._v(" "+_vm._s(_vm.$t("EMPLOYEES.STATUS." + item.status))+" ")])]}},{key:"item.duplicates",fn:function(ref){
var item = ref.item;
return [(_vm.checkForDub(item))?_c('a',{on:{"click":function($event){return _vm.onShowDublicateModal(item)}}},[_c('v-row',{staticClass:"justify-center"},[_c('v-img',{staticStyle:{"max-height":"18px","max-width":"18px"},attrs:{"src":require("@/assets/svg/database.svg")}}),_c('span',{staticClass:"pl-2",staticStyle:{"color":"#005089"}},[_vm._v(_vm._s(_vm.$t("EMPLOYEES.DUPLICATES")))])],1)],1):_vm._e(),(_vm.assingedWaitForApproval(item))?_c('span',[_vm._v(_vm._s(_vm.$t("EMPLOYEES.ASSINED")))]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.showAcceptRejectButtons(item))?_c('v-row',{staticClass:"align-center justify-center"},[_c('a',{staticClass:"d-flex flex-wrap",staticStyle:{"min-width":"90px"},on:{"click":function($event){return _vm.onShowAcceptedModal(item)}}},[_c('v-img',{staticStyle:{"max-height":"18px","max-width":"18px"},attrs:{"src":require("@/assets/svg/check-circle.svg")}}),_c('span',{staticClass:"pr-2 pl-2",staticStyle:{"color":"green"}},[_vm._v(" "+_vm._s(_vm.$t("GENERAL.ACCEPCT"))+" ")])],1),_c('a',{staticClass:"d-flex flex-wrap",staticStyle:{"min-width":"90px"},on:{"click":function($event){return _vm.onShowRejectedModal(item)}}},[_c('v-img',{staticStyle:{"max-height":"18px","max-width":"18px"},attrs:{"src":require("@/assets/svg/close-circle.svg")}}),_c('span',{staticClass:"pr-2 pl-2",staticStyle:{"color":"red"}},[_vm._v(" "+_vm._s(_vm.$t("GENERAL.DENY"))+" ")])],1)]):_vm._e()]}},{key:"item.download",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.onDownloadApplicationPressed(item)}}},[_c('img',{staticStyle:{"max-height":"18px","max-width":"18px"},attrs:{"src":require("@/assets/svg/download.svg")}})])]}}])})],1)])],1),_c('v-dialog',{attrs:{"max-width":"500","content-class":"rounded-xl"},model:{value:(_vm.showDublicateModal),callback:function ($$v) {_vm.showDublicateModal=$$v},expression:"showDublicateModal"}},[(_vm.showDublicateModal)?_c('duplicates-modal',{attrs:{"application":_vm.selectedApplication},on:{"closeDialog":function($event){return _vm.onHideDublicateModal()}}}):_vm._e()],1),_c('v-dialog',{attrs:{"max-width":"500","content-class":"rounded-xl"},model:{value:(_vm.showAcceptedModal),callback:function ($$v) {_vm.showAcceptedModal=$$v},expression:"showAcceptedModal"}},[(_vm.showAcceptedModal)?_c('accepted-modal',{attrs:{"mandate":_vm.existingMandate,"application":_vm.selectedApplication},on:{"closeDialog":function($event){return _vm.onHideAcceptedModal()}}}):_vm._e()],1),_c('v-dialog',{attrs:{"max-width":"500","content-class":"rounded-xl"},model:{value:(_vm.showRejectedModal),callback:function ($$v) {_vm.showRejectedModal=$$v},expression:"showRejectedModal"}},[(_vm.showRejectedModal)?_c('rejected-model',{attrs:{"application":_vm.selectedApplication},on:{"closeDialog":function($event){return _vm.onHideRejectedModal()}}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }