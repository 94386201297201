











































































































































































































import { mixins } from "vue-class-component";
import ErrorMessageHandlerMixin from "@/misc/ErrorMessageHandler.mixins";
import { Component, Prop, Watch } from "vue-property-decorator";
import Application from "@/models/Application";
import moment from "moment";
import { APPLICATION_STORE_NAME, ApplicationStoreActions, ApplicationStoreGetters } from "@/store/application.store";
import { namespace } from "vuex-class";
import { ApplicationStatus } from "@/enum/ApplicationStatus.enum";
import { AxiosResponse } from "axios";
import UserRepository from "@/api/repositories/UserRepository";

const ApplicationStore = namespace(APPLICATION_STORE_NAME);

@Component({
  components: {
    DuplicatesModal: () => import("@/components/Duplicates.component.vue"),
    AcceptedModal: () => import("@/components/Request.Accepted.component.vue"),
    RejectedModel: () => import("@/components/Request.Rejected.component.vue"),
    PrintDialog: () => import("@/components/PrintDialog.vue")
  }
})
export default class EmployeeRequestComponent extends mixins(
  ErrorMessageHandlerMixin
) {
  @Prop({ default: true })
  public value!: number;

  @Prop({ default: false })
  private loading!: boolean;

  private existingMandate: string | undefined = undefined;

  @ApplicationStore.Getter(ApplicationStoreGetters.CURRENT_APPLICATION)
  private app!: Application[];

  private selectedApplication?: Application;

  private itemsPerPage: number = 10;

  private showDublicateModal = false;

  private showAcceptedModal = false;

  private showRejectedModal = false;

  /**
   * Action to Download the Application PDF
   * @private
   */
  @ApplicationStore.Action(ApplicationStoreActions.DOWNLOAD_APPLICATION_PDF)
  private downloadApplicationPdf!: (payload: { application: Application, prefix: string }) => Promise<AxiosResponse>;

  private showAcceptRejectButtons(app: Application) {
    return (
      app.status === ApplicationStatus.Pending
    );
  }

  private onHideRejectedModal() {
    this.showRejectedModal = false;
  }

  private onShowRejectedModal(selected: Application) {
    this.selectedApplication = selected;
    this.showRejectedModal = true;
  }

  private async onDownloadApplicationPressed(application: Application) {
    await this.downloadApplicationPdf({
      application,
      prefix: this.$t("EMPLOYEES.FILE_DOWNLOAD_PREFIX").toString()
    });
  }

  private filterSearch(value: string, search: string, item: any) {
    const time = moment.utc(String(item.createdAt)).local().format("DD.MM.YYYY HH:MM");
    const name = item.user.firstName + " " + item.user.lastName;
    const ref = item.referenceNumber.toString();
    if (this.search_Temp === "" && (item.status === this.enabled || this.enabled === ApplicationStatus.All)) {
      return true;
    } else return (ref.includes(this.search_Temp) || time.includes(this.search_Temp) ||
        name.toUpperCase().includes(this.search_Temp.toUpperCase()))
      && (item.status === this.enabled || this.enabled === ApplicationStatus.All);
  }

  private filterOnSelect() {
    this.search = this.$t("EMPLOYEES.STATUS." + this.enabled).toString();
  }

  @Watch("search_Temp", { immediate: true })
  private filterOnType() {
    this.search = this.search_Temp;
  }

  private onHideDublicateModal() {
    this.showDublicateModal = false;
  }

  private onShowDublicateModal(selected: Application) {
    this.selectedApplication = selected;
    this.showDublicateModal = true;
  }

  private onHideAcceptedModal() {
    this.showAcceptedModal = false;
  }

  private async onShowAcceptedModal(selected: Application) {
    this.existingMandate = undefined;
    this.selectedApplication = selected;
    const userId = selected.user.sapIds?.internalUserId;

    // If the user has no userId, show the modal
    if (!userId) {
      this.showAcceptedModal = true;
      return;
    }

    // Get the user mandate
    let mandate;

    try {
      mandate = await UserRepository.getUserMandate(userId);
    } catch (e) {
      mandate = undefined;
    }

    // If the user has no mandate, show the modal
    if (!mandate || !mandate.data?.DirectDebitID || mandate.data?.DirectDebitID.length <= 0) {
      this.showAcceptedModal = true;
      return;
    }

    this.existingMandate = mandate.data?.DirectDebitID;
    this.showAcceptedModal = true;
  }

  private headers: any = [
    {
      text: this.$t("GENERAL.DATE_TIME"),
      align: "start",
      value: "createdAt",
      width: "15%"
    },
    {
      text: this.$t("GENERAL.NAME"),
      align: "start",
      value: "name",
      width: "10%"
    },
    {
      text: this.$t("EMPLOYEES.REFERENCE_NUMBER_ALT"),
      align: "center",
      value: "referenceNumber",
      width: "1%"
    },
    {
      text: this.$t("EMPLOYEES.EXISTING_CUSTOMER"),
      align: "center",
      value: "existingCustomer",
      width: "1%",
      sortable: false
    },
    {
      text: this.$t("GENERAL.STATUS"),
      align: "center",
      value: "status",
      width: "1%"
    },
    {
      text: this.$t("EMPLOYEES.DUPLICATES"),
      value: "duplicates",
      align: "center",
      width: "20%"
    },
    {
      text: this.$t("GENERAL.ACTIONS"),
      sortable: false,
      align: "start",
      value: "actions",
      width: "35%"
    },
    {
      text: this.$t("EMPLOYEES.DOWNLOAD"),
      sortable: false,
      align: "end",
      value: "download",
      width: "1%"
    }
  ];

  private search: string = "";

  private search_Temp: string = "";

  private slots: any = [
    ApplicationStatus.All,
    ApplicationStatus.Pending,
    ApplicationStatus.Accepted,
    ApplicationStatus.AssignedWaitingForApproval,
    ApplicationStatus.Rejected,
    ApplicationStatus.Running
  ];

  private checkForDub(app: Application): boolean {
    return (
      app.status === ApplicationStatus.Pending &&
      app.duplicatesCount !== undefined &&
      app.duplicatesCount > 0
    );
  }

  private assingedWaitForApproval(app: Application): boolean {
    return app.status === ApplicationStatus.AssignedWaitingForApproval;
  }

  private showDateAsMoment(app: Application) {
    return moment(app.createdAt).format("DD.MM.YYYY HH:mm");
  }

  private statusColor(status: string) {
    if (status === ApplicationStatus.Pending) {
      return "#0081F9";
    } else if (status === ApplicationStatus.Rejected) {
      return "#D92F56";
    } else if (status === ApplicationStatus.Accepted || status === ApplicationStatus.Running) {
      return "#1DB954";
    } else if (status === ApplicationStatus.AssignedWaitingForApproval) {
      return "#FFC107";
    } else {
      return "#D92F56";
    }
  }

  private enabled: ApplicationStatus = ApplicationStatus.All;
}
